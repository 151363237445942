<template>
  <div>
    <vx-card class="mb-5" title="Mis contratos." >
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>
            Consulta los contratos que todos los integrantes de la Red aceptan al entrar a nuestra comunidad.
          </p>
        </div>
      </div>

      <div class="vx-row mt-6" v-if="allContracts.length > 0 && isMounted">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <template v-for="(contract, idx ) in allContracts">
              <vs-button :disabled="enableMerchantContracts(contract)" type="border" class="sm:px-base px-4 mr-4 mb-3" :key="idx" @click="downloadContract(contract)">
                  {{contract.name}}
              </vs-button>
              <template v-if="contract.type == 3">
                <vs-button type="border" class="sm:px-base px-4 mr-4 mb-3" @click="goToCoverMerchantContract">Carátula Comisión Mercantil Inversionista</vs-button>
              </template>
            </template>
            <vs-button type="border" class="sm:px-base px-4 mr-4 mb-3" @click="goToConsentsPage">Consentimientos</vs-button>
          </div>
        </div>
      </div>

      <div class="vx-row mt-6" v-if="!isMounted">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <p>Cargando información...</p>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- <vs-popup fullscreen :active.sync="popupActive" title="Contrato" >
      <vx-card class="mb-5" title="EL CONTRATO" title-color="primary" >
        <latest-terms-contract v-if="termsActive" />
        <latest-privacy-contract v-if="privacyActive" />
        <latest-investor-merchant-contract v-if="merchantActive" />
        <latest-investor-risks-contract v-if="risksActive" />
      </vx-card>
      <vx-card class="mb-2" title-color="primary" title="TU FIRMA">
        <h5>Datos de la firma:</h5>
        <p class="my-2"><span class="bold">Nombre del documento: </span> {{currentMetaData.document}}</p>
        <p class="my-2"><span class="bold">Versión del documento: </span> {{currentMetaData.version}}</p>
        <p class="my-2"><span class="bold">Fecha de aceptación: </span> {{signatureFormatDate(currentMetaData.acceptance_date)}}</p>
        <p class="my-2"><span class="bold">Agente: </span> {{currentMetaData.agent}}</p>
        <p class="my-2"><span class="bold">IP: </span> {{currentMetaData.ip}}</p> -->
        <!--p class="my-2"><span class="bold">Validez del documento: </span> {{signatureValidityDate(currentMetaData.validity_date)}}</p-->
        <!-- <p class="my-2"><span class="bold">Correo electrónico: </span> {{currentMetaData.email}}</p>
        <h5 class="mt-5">Cadena de firma electrónica:</h5>
        <p class="text-break text-xs my-2">{{currentSignature}}</p>
      </vx-card> -->
    <!-- </vs-popup> -->
  </div>
</template>

<script>
import LatestTermsContract from "@views/share/contracts/TermsContract/LatestTermsContract.vue";
import LatestPrivacyContract from "@views/share/contracts/PrivacyContract/LatestPrivacyContract.vue";
import LatestInvestorMerchantContract from "@views/investor/contracts/LatestInvestorMerchantContract.vue";
import LatestInvestorRisksContract from "@views/investor/contracts/LatestInvestorRisksContract";
import LatestInvestorDirectBillingContract from "@views/investor/contracts/LatestInvestorDirectBillingContract";
import dateHelper from "@mixins/dateHelper";

const termsConditionsContractType = 1;
const privacyContractType = 2;
const merchantContractType = 3;
const risksContractType = 15;
const receiptPaymentsContractType = 17;
const autoInvestmentContractType = 18;
const investorDirectBillingContractType = 19;
const advertisingContractType = 22;
const secondaryMarketContractType = 26;

export default {
  name: "ContractsPage",
  data() {
    return {
      fixedContracts: [
        {id: 1, name: 'Términos y condiciones', url: 'terminos-y-condiciones', type: termsConditionsContractType },
        {id: 2, name: 'Aviso de privacidad', url: 'aviso-de-privacidad', type: privacyContractType, },
        {id: 3, name: 'Contrato de comisión mercantil', url: 'contrato-de-comision-mercantil-inversionista', type: merchantContractType },
        {id: 4, name: 'Constancia de riesgos', url: 'constancia-de-riesgos-inversionista', type: risksContractType },
        {id: 5, name: 'Domiciliación', url: 'constancia-de-riesgos-inversionista', type: investorDirectBillingContractType },
        {id: 6, name: 'Mercado secundario', url: 'mercado-secundario', type: secondaryMarketContractType },
      ],
      signedContracts:[],
      hasSignedContracts: false,
      allContracts: [],
      termsActive: false,
      privacyActive: false,
      merchantActive: false,
      risksActive: false,
      directBillingActive: false,
      secondaryMarketActive: false,
      popupActive: false,
      currentSignature: null,
      isMounted: false,
      currentMetaData: {
        acceptance_date: null,
        agent: null,
        alias: null,
        email: null,
        document: null,
        ip: null,
        password: null,
        validity_date: null,
        version: null,
      },
      enableMerchantContract:false,
      termsConditionsContractType: termsConditionsContractType,
      privacyContractType: privacyContractType,
      merchantContractType: merchantContractType,
      risksContractType:risksContractType,
      receiptPaymentsContractType: receiptPaymentsContractType,
      autoInvestmentContractType: autoInvestmentContractType,
      advertisingContractType: advertisingContractType,
      investorDirectBillingContractType: investorDirectBillingContractType,
      secondaryMarketContractType: secondaryMarketContractType,
    }
  },
  mixins: [dateHelper],
  components: {
    LatestTermsContract,
    LatestPrivacyContract,
    LatestInvestorMerchantContract,
    LatestInvestorRisksContract,
    LatestInvestorDirectBillingContract
  },
  async mounted(){
    this.isMounted = false
    this.showLoading(true)
    await this.getContractsData();
    this.isMounted = true
    this.showLoading(false)
  },
  methods: {
    enableMerchantContracts (contract) {
      if (contract.type === this.merchantContractType) {
        return this.enableMerchantContract? false:true;
      }
      else{
          return false;
      }
    },
    async goToConsentsPage(){
      this.$router.push({ name: 'consentimientosInversionista'});
    },
    async goToCoverMerchantContract(){
      this.$router.push({ name: 'infoContratoInversionista', params: { type: this.merchantContractType, downloadButtonEnabled: true, onlyCoverPage: true}});
    },
    async downloadContract(contract){
      let downloadButtonEnabled = false;
      if (contract.type == this.merchantContractType) {
        downloadButtonEnabled = true;
      }
      this.$router.push({ name: 'infoContratoInversionista', params: { type: contract.type, downloadButtonEnabled: downloadButtonEnabled}});

      // if(contract.isSigned){
      //   await this.downloadSignedContract(contract);
      // }
      // else {
      //   if(contract.type !== this.risksContractType){ // Constancia conocimiento
      //     const url = `${this.ApiDomain}/${contract.url}`;
      //     window.open(url, '_blank');
      //   } else {
      //     this.$router.push({ name: 'investorRiskAcknowledgment' });
      //   }
      // }
    },
    async downloadSignedContract(obj){
      if(obj.signature != null){
        this.currentSignature = obj.signature;
        this.currentMetaData = obj.metadata;
        this.popupActive = true;
        this.resetValues();
        if(obj.type === this.termsConditionsContractType){
          this.termsActive = true;
        }
        else if(obj.type === this.privacyContractType){
          this.privacyActive = true;
        }
        else if(obj.type === this.merchantContractType){
          this.merchantActive = true;
        }
        else if(obj.type === this.risksContractType){
          this.risksActive = true;
        }
        else if(obj.type === this.investorDirectBillingContractType){
          this.directBillingActive = true;
        }
        else if(obj.type === this.secondaryMarketContractType){
          this.secondaryMarketActive = true;
        }
      }
    },
    resetValues(){
      this.termsActive = false;
      this.privacyActive = false;
      this.merchantActive = false;
      this.risksActive = false;
      this.directBillingActive = false;
      this.secondaryMarketActive = false;
    },
    async getContractsData(){
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getContracts`);
        this.signedContracts = res.data.investor;
        this.hasSignedContracts = res.data.investor && res.data.investor.length > 0;

        const defaultContracts = res.data.default;
        const invContracts = res.data.investor;
        let allContracts = [];

        defaultContracts.forEach(df => {
          if(df.type != this.receiptPaymentsContractType && df.type != this.autoInvestmentContractType && df.type != this.advertisingContractType){
            const iinv = invContracts.find(f => f.contract.type === df.type);
            const fiinv = this.fixedContracts.find(f => f.type === df.type);
            if(iinv != null){
              if(df.type === this.risksContractType && iinv.signature != null){
                this.enableMerchantContract=true;
              }
              allContracts.push({
                name: df.name,
                type: df.type,
                url: fiinv.url,
                isSigned: (iinv.signature != null),
                signature: iinv.signature ?? null,
                metadata: iinv.metadata ?? null,
              })
            }
            else {
              allContracts.push({
                name: df.name,
                type: df.type,
                url: fiinv.url,
                isSigned: false,
                signature: null,
                metadata: null
              })
            }
          }
        })

        this.allContracts = allContracts;
      }
      catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>